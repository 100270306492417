<template>
  <div id="app">
    <!-- Transition chargement page -->
    <!--https://www.npmjs.com/package/vue-page-transition-->
    <!-- demo -->
    <!--https://orlandster.github.io/vue-page-transition/#/-->
    <modal ref="modalName" data-nosnippet>
      <template v-slot:body>
      </template>
    </modal>
    <vue-page-transition name="overlay-left-right">
      <!-- view -->
      <router-view/>
    </vue-page-transition>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: 'Home',
  components: {
    Modal
  },
  mounted() {
    localStorage.setItem("acceptation", "0")
    this.$refs.modalName.openModal();
  },
  updated() {
    if (localStorage.acceptation === "0") {
      this.$refs.modalName.openModal();
    }
  }
}
</script>

<style lang="scss">

/* Bloque scroll quand modal is open */
.overflow-hidden {
  overflow: hidden;
}

/* couleur fond transition entre page */
:root {
  --overlay-bg: white !important;
  --transition-duration: .5s !important;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
/* Logo france relance */
.logoFR {
  position: absolute;
  right: 0;
  bottom: 0;
}

.blocLogo {
  text-align: center;
  margin-top: 3%;
}

.violet {
  color: #442765;
}

.bleu {
  color: #7c84b5;
}
hr.style {
  height: 4px;
  border: 0;
  box-shadow: 0 4px 4px -4px #8c8b8b inset;
  margin: 0 auto !important;
}

.bullet {
  display: flex;
  align-items: center;
  justify-content: center;
}

/************************/
/* General              */
/************************/
/* Viga Regular */
@font-face {
  font-family: 'Viga Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Viga Regular'), url('./assets/fonts/Viga-Regular.woff') format('woff');
  font-display: swap;
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('./assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: swap;
}

#app {
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6, #nav, footer {
  font-family: 'Viga Regular', sans-serif !important;
  color: #442765;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  color: #818494;
}
/*+++++++++++++++++++++*/
/* Post dans page blog */
/*+++++++++++++++++++++*/
blockquote {
  text-align: center !important;
  color: #442765 !important;
  font-weight: bold;
}
.image_blog {
  width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.image_blog_titre {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

/*+++++++++++++++++++++*/
/* CRUMB STYLE         */
/*+++++++++++++++++++++*/
/*https://github.com/KazarminDmitriy/vue2-crumbs*/
.nav_crumbs > .ul_crumbs {
  display: inline-flex !important;
  list-style-type: none;
  padding-inline-start: 0px;

}
.li_crumbs:not(:first-child):before {
  content: '\\';
  font-weight: bold;
  color: #442765;
}

.a_crumbs {
  text-decoration: none;
  padding: 0 4px;
  font-size: 0.8rem;
}

.a_crumbs:hover {
  text-decoration: none;
  letter-spacing: 1.5px;
  font-weight: bold;
}

.last_li_crumbs > span {
  color: #7C84B5;
}


</style>

