import { render, staticRenderFns } from "./Coaching.vue?vue&type=template&id=c9976f08&scoped=true&"
import script from "./Coaching.vue?vue&type=script&lang=js&"
export * from "./Coaching.vue?vue&type=script&lang=js&"
import style0 from "./Coaching.vue?vue&type=style&index=0&id=c9976f08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9976f08",
  null
  
)

export default component.exports